<template>
  <b-modal
    id="module-detail"
    :title="titleModal"
    size="md"
    centered
    hide-footer
  >
    <form @submit.prevent="handleValidate">
      <basic-input
        required
        :disabled="isDetail"
        label="Mã chủ đề"
        placeholder="--Nhập mã chủ đề--"
        :value.sync="moduleInfo.code"
        name="code"
        :state="validateState('code')"
        :invalidFeedback="errors.first('code')"
        v-validate="'required|alpha_num'"
        data-vv-as="Mã chủ đề"
        maxlength="50"
      />
      <basic-input
        required
        label="Chủ đề"
        placeholder="--Nhập chủ đề--"
        :value.sync="moduleInfo.name"
        :disabled="isDetail"
        name="name"
        :state="validateState('name')"
        :invalidFeedback="errors.first('name')"
        v-validate="'required'"
        data-vv-as="Chủ đề"
        maxlength="100"
      />
      <div
        v-if="!isDetail"
        class="d-flex justify-content-end align-items-center"
      >
        <b-button class="btn" type="button" @click="resetModal"> Huỷ </b-button>
        <b-button class="btn btn-success ml-3" type="submit">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { MODAL_TYPE } from '@/core/plugins/constants';
export default {
  name: 'ModuleDetail',
  props: {
    module: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: MODAL_TYPE.DETAIL,
    },
  },
  watch: {
    module: {
      deep: true,
      handler(val) {
        this.moduleInfo = {
          id: val.id,
          code: val.code,
          name: val.name,
        };
      },
    },
  },
  data() {
    return {
      url: {
        createModule: '/LessonModule',
        modifyModule: '/LessonModule',
      },
      moduleInfo: {
        id: '',
        code: '',
        name: '',
      },
    };
  },
  computed: {
    isCreate() {
      return this.type === MODAL_TYPE.CREATE;
    },
    isDetail() {
      return this.type === MODAL_TYPE.DETAIL;
    },
    titleModal() {
      switch (this.type) {
        case MODAL_TYPE.CREATE:
          return 'Thêm chủ đề mới';
        case MODAL_TYPE.EDIT:
          return 'Chỉnh sửa chủ đề';

        default:
          return 'Xem chi tiết chủ đề';
      }
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('module-detail');
    },
    onClickSaveButton() {
      if (this.isCreate) {
        this.createModule();
      } else {
        this.modifyModule();
      }
    },
    resetModal() {
      this.$emit('reset');
    },
    createModule() {
      let params = {
        code: this.moduleInfo.code,
        name: this.moduleInfo.name,
      };
      this.$api
        .post(this.url.createModule, params)
        .then((res) => {
          if (res.error) {
            this.$toastr.e({
              title: 'Lỗi',
              msg: res.error.message,
              preventDuplicates: true,
            });
            return;
          }
          this.$emit('save');
          this.hideModal();
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error?.message,
            preventDuplicates: true,
          });
        });
    },
    modifyModule() {
      let params = {
        id: this.moduleInfo.id,
        code: this.moduleInfo.code,
        name: this.moduleInfo.name,
      };
      this.$api
        .put(`${this.url.modifyModule}/${this.moduleInfo.id}`, params)
        .then((res) => {
          if (res.error) {
            this.$toastr.e({
              title: 'Lỗi',
              msg: res.error.message,
              preventDuplicates: true,
            });
            return;
          }
          this.$emit('save');
          this.hideModal();
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
            preventDuplicates: true,
          });
        });
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.onClickSaveButton();
        }
        return;
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
  },
};
</script>

<style scoped></style>
