<template>
  <div class="module-list-page w-100">
    <basic-subheader v-if="isWritePermission">
      <template v-slot:actions>
        <b-button
          class="btn btn-success ml-2"
          type="button"
          @click="createItem"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm chủ đề mới
        </b-button>
      </template>
    </basic-subheader>
    <b-container fluid class="module-list-page__body mb-6 mt-6">
      <b-row>
        <b-col>
          <div class="card card-custom gutter-b">
            <div class="card-body mt-0">
              <template-table
                :column="column"
                :data="data"
                :paging="paging"
                :tableAction="false"
                :selectAction="false"
                :searchAction="false"
                @sortBy="sortRequest"
              >
                <template v-slot:body="{ item }">
                  <td>
                    <div class="ellipsis">
                      {{ item.code }}
                    </div>
                  </td>
                  <td>
                    <div class="ellipsis">
                      {{ item.name }}
                    </div>
                  </td>
                  <td>
                    <action-dropdown
                      :value="item"
                      :show_delete="false"
                      :show_copy="false"
                      :show_edit="isWritePermission"
                      :boundary="data.length > 2 ? 'scrollParent' : 'window'"
                      @edit="editItem"
                      @view="viewItem"
                    >
                      <b-dropdown-text
                        v-if="isWritePermission"
                        tag="div"
                        class="navi-item cursor-pointer"
                        @click="copyItem(item)"
                      >
                        <a class="navi-link text-primary">
                          <span class="menu-icon svg-icon svg-icon-sm">
                            <inline-svg
                              class="svg-icon"
                              src="/media/svg/icons/Neolex/Basic/copy.svg"
                            />
                          </span>
                          <span class="navi-text text-primary">Copy</span>
                        </a>
                      </b-dropdown-text>
                    </action-dropdown>
                  </td>
                  <td class="text-center">
                    {{ $moment(item.updateDate).format('DD/MM/YYYY') }}
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <avatar
                        size="40px"
                        :text="item.updaterName"
                        :src="item.updaterImage"
                        :rounded="true"
                      />
                      <div class="d-flex flex-column ml-5">
                        <p class="mb-0 module-list-page__body__updater-name">
                          {{ item.updaterName }}
                        </p>
                        <p
                          class="mt-2 mb-0 module-list-page__body__updater-code"
                        >
                          {{ item.updaterUsername }}
                        </p>
                      </div>
                    </div>
                  </td>
                </template>
              </template-table>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <module-detail
      :type="modalType"
      :module="selectedModule"
      @save="callbackSaveModule"
      @reset="resetModal"
    />
  </div>
</template>

<script>
import { MODAL_TYPE } from '@/core/plugins/constants';
import ModuleDetail from './ModuleDetail';

export default {
  name: 'ModuleList',
  components: { ModuleDetail },
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      filter: {
        searchKey: null,
      },
      sort: {
        by: null,
        order: null,
      },
      column: [
        {
          key: 'code',
          label: 'Mã',
          sortable: true,
          style: {
            width: '10%',
          },
        },
        {
          key: 'name',
          label: 'Module',
          sortable: true,
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
          style: {
            width: '5%',
          },
        },
        {
          key: 'updateDate',
          label: 'Cập nhật lần cuối',
          sortable: false,
          class: 'text-center',
        },
        {
          key: 'updatedName',
          label: 'Người cập nhật',
          sortable: false,
        },
      ],
      data: [],

      isCreateMode: false,
      selectedModule: {
        code: '',
        title: '',
      },
      modalType: null,
    };
  },
  computed: {
    searchParams() {
      return {
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
      };
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.paging.page === 1 ? this.loadData() : (this.paging.page = 1);
    },
    sort: {},
  },
  methods: {
    createItem() {
      this.selectedModule = {
        id: '',
        code: '',
        title: '',
      };
      this.modalType = MODAL_TYPE.CREATE;
      this.$bvModal.show('module-detail');
    },
    editItem(item) {
      this.selectedModule = {
        id: item.id,
        code: item.code,
        name: item.name,
      };
      this.modalType = MODAL_TYPE.EDIT;
      this.$bvModal.show('module-detail');
    },
    viewItem(item) {
      this.selectedModule = {
        id: item.id,
        code: item.code,
        name: item.name,
      };
      this.modalType = MODAL_TYPE.DETAIL;
      this.$bvModal.show('module-detail');
    },
    copyItem(item) {
      this.selectedModule = {
        id: item.id,
        code: item.code,
        name: item.name,
      };
      this.modalType = MODAL_TYPE.CREATE;
      this.$bvModal.show('module-detail');
    },
    sortRequest(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
      this.loadData();
    },
    callbackSaveModule() {
      this.loadData();
    },
    resetModal() {
      this.modalType = null;
      this.selectedModule = {
        id: '',
        code: '',
        title: '',
      };
      this.$bvModal.hide('module-detail');
    },
    loadData() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get('/LessonModule', {
          params: this.searchParams,
        })
        .then((res) => {
          let data = res.data.items;
          this.data = data.map((item) => {
            return {
              code: item.code,
              id: item.id,
              name: item.name,
              updateDate: item.updateDate,
              updaterUsername: item.updaterUsername,
              updaterName: item.updaterName,
              updaterImage: item.updaterImage?.url,
            };
          });
          this.paging.total = res.data.total;
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.module-list-page {
  .module-list-page__body {
    .module-list-page__body__updater-name {
      font-weight: 600;
      font-size: 13px;
      color: #515356;
    }

    .module-list-page__body__updater-code {
      font-size: 12px;
      color: #888c9f;
    }
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
